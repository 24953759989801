.sidebar {
	.nav-link {
		text-transform: capitalize;
	}
}
/*form styles*/

.m-form {
	.container {
		.row {
			margin-right: -10px;
			margin-left: -10px;

			> * {
				padding-right: 10px;
				padding-left: 10px;
			}
		}
	}

	.dataTables_wrapper .dataTables_filter input, 
	.dataTables_wrapper .dataTables_length select, 
	.form-control{
		text-transform: none;
		padding: 10px 15px;
		border: 0;
		font-size: 16px;
		line-height: 26px;
		color: #383a35;
		height: 54px;

		&::placeholder{
			opacity: 0.5;
			text-transform: none;
			color: #383a35;
		}

		&:focus-visible,
		&:focus{
			outline: none;
			border: 0;
			box-shadow: none;
		}

	}
	input[type=number] {
		-moz-appearance: textfield;
	
		&::placeholder{
			text-align: left;
		}
	}
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	.form-group--irpf {
		display: flex;
		justify-content: space-between;
		align-items: center; 
		.form-control{
			text-align: right;
		}
	}
	
}